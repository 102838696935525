<template>
  <div class="ui-pl-6 ui-pr-3 ui-py-4 ui-flex ui-mb-2 shelf-item c-bg-white">
    <icon name="drag" color="grey-400" />
    <div class="ui-flex-grow flex-centered">
      <div class="c-text-b2">
        {{ shelf.title }}
      </div>

      <div>
        <tooltip :showTooltip="warningIcon.name !== 'check'">
          <template v-slot:container >
            <icon
              :name="warningIcon.name"
              :color="warningIcon.color"
              filled
              width="16"
              class="ui-mr-1 ui-inline"
            />
            <span class="c-text-b3 c-text-gray-300">
          {{ warningIcon.text }} • Atualizado em:
          {{ lastUpdated | formatDate }}
        </span>
          </template>
          <div class="c-text-b3 c-text-gray-400 ui-px-5 ui-py-4"> Os álbuns despublicados não aparecem para os alunos. Certifique-se que todo o material foi publicado corretamente em Álbuns no menu.</div>
        </tooltip>

        
      </div>
    </div>
  </div>
</template>
<script>
import { Icon } from "@estrategiahq/coruja-web-ui";
import Tooltip from "./ToolTip.vue";
export default {
  components: {
    Icon,
    Tooltip,
  },
  name: "HomeShelfItem",
  props: {
    shelf: {
      type: Object,
      required: true,
    },
  },
  computed: {
    warningIcon() {
      let unpublished =
        this.shelf?.albums?.reduce(
          (acc, album) => (!album.is_published ? acc + 1 : acc),
          0
        ) || 0;

      if (unpublished == 0) {
        return {
          name: "check",
          color: "var(--c-color-green-400)",
          text: "Todos os álbuns publicados",
        };
      }

      if (unpublished < this.shelf.albums.length) {
        return {
          name: "error",
          color: "var(--c-color-orange-400)",
          text: "Contém álbuns despublicados",
        };
      }

      return {
        name: "close",
        color: "var(--c-color-red-400)",
        text: "Todos os álbuns despublicados",
      };
    },
    lastUpdated() {
      return Date.parse(this.shelf.created_at) >
        Date.parse(this.shelf.updated_at)
        ? this.shelf.created_at
        : this.shelf.updated_at;
    },
  },
  filters: {
    formatDate(data) {
      return Intl.DateTimeFormat("pt-br", { dateStyle: "short" }).format(
        Date.parse(data)
      );
    },
  },
};
</script>

<style scoped>
.album-item {
  box-shadow: 0px 4px 16px rgba(34, 34, 34, 0.02);
  background-color: var(--c-color-white);
  border-radius: 4px;
  border: 1px solid var(--c-color-gray-100);
}
</style>