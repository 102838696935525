<template>
  <div class="ui-pa-3 full-width">
    <p class="ui-text-h6">{{ shelf.title }}</p>
    <div class="widget">
      <c-cast-card
        style="width: 80px; margin-left: 1rem"
        v-for="album in shelf.albums"
        :key="album.id"
      >
        <template v-slot:image>
          <img :src="album.image | getImage('100')" />
        </template>
        {{ album.title }}
      </c-cast-card>
    </div>
  </div>
</template>

<script>
import { CCastCard } from "@estrategiahq/coruja-web-ui";
export default {
  components: {
    CCastCard,
  },
  filters: {
    getImage(imageUrl, size) {
      return imageUrl.replace("raw", size);
    },
  },
  props: ["shelf"],
};
</script>

<style scoped>
.album-card {
  margin-right: 18px !important;
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.album-image {
  width: 140px;
  height: 140px;
  border-radius: 6px;
}

.album-text {
  font-size: 15px;
  font-weight: 700;
  padding-top: 8px;
}

.widget {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.widget.album-card {
  flex: 0 0 auto;
  margin-right: 10px;
}
</style>
