<template>
  <div>
    <div class="ui-px-6 ui-text-left ui-flex ui-flex-col ui-overflow-x-hidden">
      <h2 class="ui-py-8 c-text-h2 ui-text-left" data-cy="editor-da-home">Editor da Home</h2>

      <div class="c-container ui-px-0">
        <div class="c-row">
          <div class="c-col">
            <h3 class="c-text-h3 ui-text-left">Lista de Estantes</h3>
            <div class="ui-py-4 c-text-b2 ui-text-left ui-text-justify">
              A lista abaixo apresenta os 20 primeiros resultados, ou mais
              recentes. Estantes desabilitadas contém todos os álbuns não
              publicados e não podem ser arrastadas no visor ao lado.
            </div>

            <div class="ui-px-3">
              <c-search-bar
                placeholder="Busque pelo nome da estante"
                v-model="filter"
                @search="this.triggerSearch"
                class="c-row ui-py-3 ui-px-0"
              />

              <div class="c-row ui-px-0 scroll-container ui-overflow-auto">
                <draggable
                  class="ui-w-full ui-p-2"
                  :list="getNotSelectedShelves"
                  group="home"
                  ghost-class="ghost"
                  filter=".notPublished"
                  @filter="filterDialog = true"
                >
                  <div
                    class="container-card card-size"
                    v-for="element in getNotSelectedShelves"
                    v-bind:class="{ notPublished: !isDraggable(element) }"
                    :key="element.id"
                  >
                    <home-shelf-item :shelf="element" v-if="!loading" />
                    <c-skeleton-block height="100%" radius="0" v-if="loading" />
                  </div>
                </draggable>
              </div>
            </div>
          </div>

          <div class="c-col c-text-h3 ui-text-left">
            <h3 class="c-text-h3 ui-text-left">Visor da Home</h3>
            <div class="ui-py-4 c-text-b2 ui-text-left ui-text-justify">
              Arraste as Estantes desejadas e ordene-as no visor abaixo. Se
              alguma Estante conter álbuns desativados, estes não aparecerão ao
              aluno! Recomendamos que publique-os em Álbuns no menu.
            </div>

            <div
              class="
                c-row
                ui-justify-center
                c-bg-white
                ui-py-10 ui-ml-0 ui-mr-4
              "
            >
              <div class="stick-top-right">
                <div
                  class="device device-iphone-8"
                  style="zoom: 0.7; float: right"
                >
                  <div class="device-frame">
                    <div
                      class="device-content c-bg-gray-100"
                      style="height: 100%; overflow-y: auto"
                    >
                      <draggable
                        :list="home"
                        ghost-class="ghost"
                        group="home"
                        style="height: 100%"
                        @start="onStartDragging"
                        @end="draggedID = 0"
                      >
                        <div v-for="element in home" :key="element.title">
                          <ShelfWidget
                            v-bind:shelf="element"
                            v-if="draggedID != element.id && !loading"
                          />
                          <home-shelf-item
                            :shelf="element"
                            :key="element.id"
                            v-if="draggedID == element.id && !loading"
                          />
                        </div>
                      </draggable>
                    </div>
                  </div>
                  <div class="device-stripe"></div>
                  <div class="device-header"></div>
                  <div class="device-sensors"></div>
                  <div class="device-btns"></div>
                  <div class="device-power"></div>
                  <div class="device-home"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <modal :show="filterDialog" @close="filterDialog = false">
        <template v-slot:title>Todos os álbuns estão despublicados!</template>
        <template v-slot:description>
          <div class="c-text-b2 ui-mt-2">
            É necessário ter ao menos um álbum publicado dentro da Estante para
            poder arrastá-lo ao visor
          </div></template
        >
        <template v-slot:actions>
          <div>
            <c-button
              class="centered-button"
              color="primary"
              @click="filterDialog = false"
              >Entendi!</c-button
            >
          </div>
        </template>
      </modal>
    </div>

    <actions-footer :cancelRoute="''">
      <template v-slot:actions>
        <c-button class="ui-mr-4" @click="saveHome()">
          Salvar e publicar
          <icon name="question" outline class="c-icon--right" />
        </c-button>
      </template>
    </actions-footer>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import ShelfWidget from "../../components/ShelfWidget.vue";
import Modal from "../../components/Modal.vue";
import ActionsFooter from "../../components/ActionsFooter.vue";
import {
  CSearchBar,
  CSkeletonBlock,
  CButton,
  Icon,
} from "@estrategiahq/coruja-web-ui";
import HomeShelfItem from "../../components/HomeShelfItem.vue";

const loadingItems = Array.from({ length: 20 }, () => ({}));

export default {
  components: {
    draggable,
    ShelfWidget,
    HomeShelfItem,
    ActionsFooter,
    Modal,
    Icon,
    CSearchBar,
    CButton,
    CSkeletonBlock,
  },
  data() {
    return {
      home: [],
      shelves: loadingItems,
      timeout: null,
      filter: "",
      draggedID: 0,
      filterDialog: false,
      loading: true,
    };
  },
  mounted() {
    this.fetchHome();
    this.fetchShelves();
  },
  computed: {
    getNotSelectedShelves() {
      if (this.loading) {
        return loadingItems;
      }
      const selectedShelvesIds = this.home.map((a) => a.id);
      return this.shelves.filter(
        (a) => selectedShelvesIds.indexOf(a.id) === -1
      );
    },
  },
  watch: {
    async filter() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.fetchShelves();
      }, 400);
    },
  },
  methods: {
    async triggerSearch() {
      clearTimeout(this.timeout);
      this.fetchShelves();
    },
    isDraggable(element) {
      var unpublished = element?.albums?.reduce(
        (acc, i) => (!i.is_published ? acc + 1 : acc),
        0
      );
      if (unpublished == element?.albums?.length) {
        return false;
      }
      return true;
    },
    onStartDragging(evt) {
      this.draggedID = this.home[evt.oldIndex]?.id || 0;
    },
    async fetchHome() {
      let result = await this.$s.home.fetchHome();
      this.home = result.data;
    },
    async fetchShelves() {
      this.loading = true;
      let search = `?page=1&per_page=20`;
      search += !this.filter.trim().length
        ? ""
        : `&search=(title[icontains]:'${this.filter.trim()}')`;

      let result = await this.$s.shelf.fetchShelves(search);
      this.shelves = result.data;
      this.loading = false;
    },
    async saveHome() {
      const data = {
        type: "home",
        shelves: this.home.map((item) => item.id),
      };

      try {
        await this.$s.home.saveHome(data);
        this.emitToast("success", "Estantes publicadas com sucesso!");
      } catch (err) {
        this.emitToast(
          "error",
          "Falha ao tentar salvar home. Tente novamente!"
        );
      }
    },
    async emitToast(type, message) {
      this.$emit("toast", {
        type: type,
        text: message,
        position: "top",
        timeout: 3000,
      });
    },
    handleActionClick(type) {
      const actions = {
        OK: () => this.saveHome(),
      };
      return actions[type] && actions[type]();
    },
  },
};
</script>

<style>
.scroll-container {
  height: 626px;
  width: 100%;
  background-color: white;
}

.container-card {
  background: #ffffff;
  border: 1px solid #fafafa;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(34, 34, 34, 0.02);
  border-radius: 4px;
}

.card-size {
  height: 84px;
}

.centered-button {
  margin: auto;
  width: 220px;
}
</style>